import axios from 'axios';

const TOKEN = localStorage.getItem('ff:token');

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common['Authorization'] = TOKEN;

/**
 * @param httpMethod {string}
 * @param method {string}
 * @param data {Object}
 * @return {Promise<unknown>}
 */
export const baseRequest = ({
  data = {},
  method = '',
  httpMethod = 'POST',
} = {}) => {
  return axios({
      url: `${method}`,
      method: httpMethod,
      data,
  })
    .catch((error) => {
      throw new Error(error)
    })
    .then(({ data }) => {
      return data
    });
};
