import { baseRequest } from '../baseRequest';

/**
 * @param email {string}
 * @param password {string}
 * @return {Promise<string>}
 */
export const loginService = (email, password) => {
  return baseRequest({
    method: 'login',
    httpMethod: 'post',
    data: { email, password },
  })
    .then(() => {
      // @todo это пример, ответ надо обработать корректно и вернуть token
      return 'thisIsToken'
    })
    .catch(() => {
      return 'thisIsTokenFromCatch'
    })
};
